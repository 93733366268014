<div class="vendedor documentacao" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <div class="row">
        <div class="col-md-6 coluna-1">
            <div class="card card-primary">
                <div class="card-body">
                    @if (clientes.length > 0) {
                        <div class="ordenar-vendas row">
                            <div class="mb-3 form-group search-box has-feedback col-md-7">
                                <input type="text" placeholder="{{'seller.documentacao.coluna-1.filtro' | translate}}" class="form-control" maxlength="50" [(ngModel)]="searchFilter" />
                            </div>
                            <div class="form-check col-md-5">
                                <label class="me-2">{{ 'seller.documentacao.coluna-1.ordenar-vendas' | translate}}</label>
                                <label class="switch">
                                    <input type="checkbox" [checked]="orderByField === 'totalSales'" (click)="ordenarTotalVendasCheckbox($event)" />
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    }
                    @if ((filteredClient | filterBy:filterClient).length <= 0) {
                        <div class="vazio">{{ 'seller.documentacao.coluna-1.tabela.vazio' | translate}}</div>
                    }
                    <div class="corpo">
                        @if ((filteredClient | filterBy:filterClient).length > 0) {
                            <ng-scrollbar height="auto" class="contentvgac3" tabindex="0">
                                <table class="tabela-clientes table table-bordered table-hover table-responsive">
                                    <thead>
                                        <tr>
                                            <th class="col-md-6" (click)="ordenar('fantasyName', false)">
                                                <span>{{ 'seller.documentacao.coluna-1.tabela.header.empresa' | translate}}
                                                    @if (orderByField == 'fantasyName') {
                                                        <span>
                                                            <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                        </span>
                                                    }
                                                </span>
                                            </th>
                                            <th class="col-md-3" (click)="ordenar('cityName', false)">
                                                <span>{{ 'seller.documentacao.coluna-1.tabela.header.cidade' | translate}}
                                                    @if (orderByField == 'cityName') {
                                                        <span>
                                                            <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                        </span>
                                                    }
                                                </span>
                                            </th>
                                            <th class="col-md-3" (click)="ordenar('subscriptionStatus', false)">
                                                <span>{{ 'seller.documentacao.coluna-1.tabela.header.situacao' | translate}}
                                                    @if (orderByField == 'subscriptionStatus') {
                                                        <span>
                                                            <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                        </span>
                                                    }
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbodyhp">
                                        @for (cliente of filteredClient; track cliente) {
                                            <tr [ngClass]="cliente?.companyId == clienteSelecionado?.companyId ? 'itemativo' : ''" (click)="selecionar(cliente.companyId)">
                                                <td class="col-md-6">
                                                    <div class="truncate"><span>{{cliente.fantasyName}}</span></div>
                                                </td>
                                                <td class="col-md-3">
                                                    <div class="truncate"><span>{{cliente.cityName}}</span></div>
                                                </td>
                                                @switch (cliente.subscriptionStatus) {
                                                    @case (0) {
                                                        <td class="col-md-3">
                                                            <button type="button" class="btn btn-sm btn-labeled-custom btn-light" (click)="analisarContrato($event, cliente.companyId, cliente.subscriptionStatus)">
                                                                <span class="label btn-extrapeq"><i class="fa fa-exclamation"></i></span>
                                                                <span class="text">{{ 'seller.documentacao.coluna-1.tabela.botoes.analisar' | translate}}</span>
                                                            </button>
                                                        </td>
                                                    }
                                                    @case (1) {
                                                        <td class="col-md-3 w-100 d-flex justify-content-space-between align-items-center">
                                                            <div class="label">
                                                                <span class="circle circle-danger circle-lg text-start icone-plus"></span>
                                                                {{ 'seller.documentacao.coluna-1.tabela.status.rejeitado' | translate}}
                                                            </div>
                                                            <button type="button" class="float-end btn-sm btn btn-labeled btn-light mini" title="{{ 'seller.documentacao.coluna-1.tabela.botoes.editar' | translate}}" (click)="analisarContrato($event, cliente.companyId, cliente.subscriptionStatus)">
                                                                <i class="fa fa-pencil"></i>
                                                            </button>
                                                        </td>
                                                    }
                                                    @case (2) {
                                                        <td class="col-md-3 w-100 d-flex justify-content-space-between align-items-center">
                                                            <div class="label">
                                                                <span class="circle circle-success circle-lg text-start icone-plus"></span>
                                                                {{ 'seller.documentacao.coluna-1.tabela.status.aprovado' | translate}}
                                                            </div>
                                                            <button type="button" class="float-end btn-sm btn btn-labeled btn-light mini" title="{{ 'seller.documentacao.coluna-1.tabela.botoes.editar' | translate}}" (click)="analisarContrato($event, cliente.companyId, cliente.subscriptionStatus)">
                                                                <i class="fa fa-pencil"></i>
                                                            </button>
                                                        </td>
                                                    }
                                                }
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </ng-scrollbar>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 coluna-2">
            <div class="conteudo relcard">
                <div class="card card-primary">
                    <div class="card-body">
                        @if (clientes.length > 0) {
                            <ng-scrollbar height="auto" class="dados-cliente">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>{{ 'seller.documentacao.coluna-2.empresa' | translate}}</label>
                                        <input type="text" disabled="" placeholder="{{clienteSelecionado.fantasyName}}" class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 pr0">
                                        <label>{{ 'seller.documentacao.coluna-2.cnpj' | translate}}</label>
                                        <input type="text" disabled="" placeholder="{{clienteSelecionado.cnpjFormatted}}" class="form-control form-control-sm cnpj">
                                    </div>
                                    <div class="col-md-4 pr0">
                                        <label>{{ 'seller.documentacao.coluna-2.cidade' | translate}}</label>
                                        <input type="text" disabled="" placeholder="{{clienteSelecionado.cityName}}" class="form-control form-control-sm">
                                    </div>
                                    <div class="col-lg-5 col-md-4 documentacao">
                                        <label>{{ 'seller.documentacao.coluna-2.documentacao.titulo' | translate}}</label><br />
                                        @if (clienteSelecionado.documentation) {
                                            <button type="button" class="btn btn-labeled btn-light btn-download" (click)="download(); $event.stopPropagation();">
                                                <span class="label"><i class="fa fa-file-zip-o "></i></span>
                                                <span class="text">{{ 'seller.documentacao.coluna-2.documentacao.download' | translate}}</span>
                                            </button>
                                        } @else if (isDocumentationLoaded) {
                                            <span>{{ 'seller.documentacao.coluna-2.documentacao.nao-existe' | translate}}</span>
                                        }
                                        <ngx-ui-loader overlayColor="rgba(40, 40, 40, 0.4)" [fgsSize]="30" [loaderId]="'loader-documentacao'"></ngx-ui-loader>
                                    </div>
                                </div>
                                <h5><b>{{ 'seller.documentacao.coluna-2.informacoes-mercado.titulo' | translate}}</b></h5>
                                <div class="row informacoes-mercado">
                                    <div class="col-md-6 coluna-esq">
                                        <div class="row">
                                            <div class="row col-md-12">
                                                <small class="col-lg-7 col-md-9 text-end"><b class="titulo">{{ 'seller.documentacao.coluna-2.informacoes-mercado.cadastro-aprovado' | translate}}</b></small>
                                                <small class="col-lg-5 col-md-3 row valor" >{{clienteSelecionado.totalApproved}} {{ (clienteSelecionado.totalApproved <= 1 ? 'seller.documentacao.coluna-2.informacoes-mercado.empresa-singular' : 'seller.documentacao.coluna-2.informacoes-mercado.empresa-plural') | translate}}</small>
                                            </div>
                                            <div class="row col-md-12">
                                                <small class="col-lg-7 col-md-9 text-end"><b class="titulo">{{ 'seller.documentacao.coluna-2.informacoes-mercado.limite-medio' | translate}}</b></small>
                                                <small class="col-lg-5 col-md-3 row valor">{{clienteSelecionado.averageLimitFormatted}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 coluna-dir">
                                        <div class="row">
                                            <div class="col-md-12 col-1-cli text-center">
                                                <div tooltip="{{ 'seller.documentacao.coluna-2.taxa-aprovacao.informativo' | translate}}"><label class="elem99">{{ 'seller.documentacao.coluna-2.taxa-aprovacao.titulo' | translate}}</label> &nbsp; <em class="fa fa-info-circle"></em></div>
                                                <br />
                                                <div attr.data-label="{{clienteSelecionado.approvedPercentage}}%" class="zi0 radial-bar radial-bar-{{clienteSelecionado.approvedPercentage}} radial-bar-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5><b>{{ 'seller.documentacao.coluna-2.situacao-cadastro.titulo' | translate}}</b></h5>
                                <form name="formSubscription" id="formSubscription" class="form-subscription form-validate" [ngClass]="editMode ? 'edit' : ''" novalidate="">
                                    <div class="row mb-3 form-group situacao-cadastro">
                                        <div class="row col-md-12 col-lg-12">
                                            <small class="col-lg-3 col-md-4 text-end"><b>{{ 'seller.documentacao.coluna-2.situacao-cadastro.status' | translate}}</b></small>
                                            @if (editMode) {
                                                <label class="switch switch-lg status-non-edit col-md-5 col-lg-1">
                                                    <input type="checkbox" [(ngModel)]="clienteSelecionado.approved" [ngModelOptions]="{standalone: true}" (change)="mudancaAprovacao()">
                                                    <span></span>
                                                </label>

                                                @if (clienteSelecionado.approved) {
                                                    <small class="col-md-2 col-lg-6"> {{ 'seller.documentacao.coluna-1.tabela.status.aprovado' | translate }}</small>
                                                } @else {
                                                    <small class="col-md-2 col-lg-6"> {{ 'seller.documentacao.coluna-1.tabela.status.rejeitado' | translate }}</small>
                                                }
                                            } @else {
                                                <div class="col-lg-3 col-md-7 status">
                                                    <span class="circle {{clienteSelecionado.subscriptionStatus == 0 ? 'circle-warning' : (clienteSelecionado.subscriptionStatus == 1 ? 'circle-danger' : 'circle-success')}} circle-lg text-start icone-plus"></span>
                                                    <small >{{clienteSelecionado.status}}</small>
                                                    <button type="button" class="float-end btn-sm btn btn-labeled btn-light" title="{{ 'seller.documentacao.coluna-1.tabela.botoes.editar' | translate}}" (click)="analisarContrato($event, clienteSelecionado.companyId, clienteSelecionado.subscriptionStatus)">
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    @if (clienteSelecionado?.subscriptionStatus == 2 || editMode) {
                                        <div class="row mb-3 form-group situacao-cadastro-edicao">
                                            <div class="col-md-12 col-lg-6 d-flex">
                                                <small class="col-lg-6 col-md-4 text-end"><b>{{ 'seller.documentacao.coluna-2.situacao-cadastro.limite-disponivel.label' | translate}}</b></small>
                                                <div class="input-group white-space-nowrap">
                                                    <span class="input-group-text">
                                                        <span>R$</span>
                                                    </span>
                                                    <input name="limit" type="text" [disabled]="!editMode || !clienteSelecionado.approved" [(ngModel)]="clienteSelecionado.limit" class="form-control form-control-sm zi0" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0.01" max="100000" maxlength="9" required />
                                                </div>
                                            </div>
                                            @if (editMode) {
                                                <div class="col-md-12 col-lg-6 text-center">
                                                    <input type="button" class="btn btn-sm btn-primary" value="{{ 'seller.documentacao.coluna-2.situacao-cadastro.salvar.botao' | translate}}" (click)="salvar()" />
                                                    &nbsp;
                                                    <input type="button" class="btn btn-sm btn-light" value="{{ 'seller.documentacao.coluna-2.situacao-cadastro.cancelar' | translate}}" (click)="cancelar()" />
                                                </div>
                                            }
                                        </div>
                                    }
                                </form>
                            </ng-scrollbar>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
