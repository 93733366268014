import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from 'app/account/account.service';
import { NotificationService } from 'app/_services/notification.service';
import { SellerDocumentacaoService } from './documentacao.service';

import { Globals } from 'app/_models/globals';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SellerDocumentationClient } from '../_models/sellerDocumentationClient';

declare const $: any;
declare const findInArray: any;
declare const sort_by: any;

@Component({ selector: 'app-documentacao', templateUrl: './documentacao.component.html', styleUrls: ['./documentacao.component.scss'] })
export class SellerDocumentacaoComponent implements OnInit
{
    get f() { return this.formSubscription.controls; }

    isOnError: boolean;
    Swal: any;
    clientes: SellerDocumentationClient[] = [];
    filteredClient: SellerDocumentationClient[] = [];
    clienteSelecionado: SellerDocumentationClient;
    editMode: boolean;
    orderByField: string;
    reverseSort: boolean;
    _searchFilter: string;
    formSubscription: UntypedFormGroup;
    isDocumentationLoaded: boolean = false;

    // tslint:disable-next-line: max-line-length
    constructor(private route: ActivatedRoute, private accountService: AccountService, private notificationService: NotificationService, private documentacaoService: SellerDocumentacaoService, private globals: Globals,
        private formBuilder: UntypedFormBuilder, private ngxLoader: NgxUiLoaderService, private router: Router, private translate: TranslateService)
    // tslint:disable-next-line: one-line
    {
    }

    ngOnInit()
    {
        if (!this.accountService.checkIfIsLogged())
        {
            this.ngxLoader.startLoader('loader-principal');
            return;
        }

        this.editMode = false;
        this.filteredClient = [];
        this.clientes = [];
        this.orderByField = 'totalSales';
        this.reverseSort = true;

        this.buscarClientes(3);

        this.formSubscription = this.formBuilder.group({
            limit: [{ tipo: '' }, Validators.required]
        });
    }

    analisarContrato($event, company_id, subscription_status)
    {
        $event.stopPropagation();
        this.selecionar(company_id);
        this.editMode = true;
        this.clienteSelecionado.approved = (this.clienteSelecionado.subscriptionStatus !== 1);
    }

    buscarClientes(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.documentacaoService.getAll().subscribe({
            next: (response: ApiResponseModel<SellerDocumentationClient[]>) => {
                if (response && response.success) {
                    this.clientes = response.result;
                    this.filteredClient = this.clientes;
                    this.sortList();

                    if (response.result.length > 0) {
                        const companyId = this.filteredClient[0].companyId;
                        this.selecionar(companyId);
                    }
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.buscarClientes(++nAttempts);
            }
        });
    }

    cancelar()
    {
        this.editMode = false;
    }

    download()
    {
        try
        {
            window.open(this.clienteSelecionado.documentation);
        }
        catch (e)
        {
            console.log(e);
        }
    }

    filterClient(searchFilter: string)
    {
        var filtered = [];

        if (this === undefined) return [];

        if (this._searchFilter === undefined || this._searchFilter.length <= 0)
            return this.clientes;

        searchFilter = this._searchFilter.replace('-', '').replace('.', '').replace('.', '').replace('/', '');

        this.clientes.forEach(function (item)
        {
            var status = item.status === 'Pendente' ? 'pendenteanalisar cadastro' : item.status; // Adiciona o texto analisar cadastro para o status pendente

            if (item.fantasyName.toLowerCase().indexOf(searchFilter.toLowerCase()) != -1 ||
                item.cityName.toLowerCase().indexOf(searchFilter.toLowerCase()) != -1 ||
                item.cnpj.toLowerCase().indexOf(searchFilter.toLowerCase()) != -1 ||
                status.toLowerCase().indexOf(searchFilter.toLowerCase()) != -1)
            {
                filtered.push(item);
            }
        });

        return filtered;
    }

    mudancaAprovacao()
    {
        if (!this.clienteSelecionado.approved)
        {
            this.clienteSelecionado.limit = null;
        }
    }

    ordenar(coluna, direcao)
    {
        if (coluna === this.orderByField && coluna === 'totalSales' && this.reverseSort)
        {
            this.orderByField = 'fantasyName';
            this.reverseSort = false;
            return;
        }

        this.reverseSort = (this.orderByField === coluna) ? !this.reverseSort : direcao;
        this.orderByField = coluna;

        this.sortList();
    }

    ordenarTotalVendasCheckbox($event) {
        let isTotalSales = $($event.target).is(":checked");

        this.ordenar(isTotalSales ? 'totalSales' : 'fantasyName', isTotalSales)
    }

    salvar() {
        if (this.clienteSelecionado.approved && (this.clienteSelecionado.limit === undefined || this.clienteSelecionado.limit === undefined || this.clienteSelecionado.limit === null || this.clienteSelecionado.limit === 0))
            return;

        this.clienteSelecionado.subscriptionStatus = this.clienteSelecionado.approved ? 2 : 1;

        this.clienteSelecionado.status = this.clienteSelecionado.approved ? this.globals.translate('seller.documentacao.coluna-1.tabela.status.aprovado') : this.globals.translate('seller.documentacao.coluna-1.tabela.status.rejeitado');

        if (!this.clienteSelecionado.approved)
            this.clienteSelecionado.limit = undefined;

        this.ngxLoader.startLoader('loader-principal');

        var buyerCompanyId = this.clienteSelecionado.buyerCompanyId;
        var subscriptionStatus = this.clienteSelecionado.status == 'Aprovado' ? 2 : 1;
        var limit = this.clienteSelecionado.limit;

        this.documentacaoService.updateBuyersLimit(buyerCompanyId, subscriptionStatus, limit).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response != null) {
                    this.notificationService.showSuccessToastr(this.globals.translate('seller.documentacao.coluna-2.situacao-cadastro.salvar.sucesso.corpo'));

                    this.cancelar();
                    this.ngxLoader.stopLoader('loader-principal');
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: () => {
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    selecionar(companyId: number) {
        this.isDocumentationLoaded = false;

        let clienteSelecionado = findInArray(this.clientes, "companyId", companyId) as SellerDocumentationClient;

        if (clienteSelecionado.subscriptionStatus === 0 && clienteSelecionado.approved)
            clienteSelecionado.approved = false;

        this.clienteSelecionado = clienteSelecionado;

        if (clienteSelecionado.documentation)
            return this.isDocumentationLoaded = true;

        this.ngxLoader.startLoader('loader-documentacao');

        this.documentacaoService.getDocumentation(clienteSelecionado.holdingBuyerId).subscribe({
            next: (response: ApiResponseModel<string>) => {
                if (response && response.success) {
                    this.clienteSelecionado.documentation = response.result;
                }

                this.isDocumentationLoaded = true;
                this.ngxLoader.stopLoader('loader-documentacao');
            }, error: () => {
                this.isDocumentationLoaded = true;
                this.ngxLoader.stopLoader('loader-documentacao');
            }
        })
    }

    sortList()
    {
        if (this.filteredClient === null || this.filteredClient === undefined)
            return;

        this.filteredClient.sort(sort_by([{ name: this.orderByField, reverse: this.reverseSort }]));
    }

    get searchFilter(): string
    {
        return this._searchFilter;
    }

    set searchFilter(value: string)
    {
        this._searchFilter = value;
        this.filteredClient = this.filterClient(value);
        this.sortList();
    }
}
